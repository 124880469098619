import { BrowserRouter, Routes, Route } from 'react-router-dom';
import HomePage from './HomePage';
import WaiverForm from "./WaiverForm";
import LoginForm from "./LoginForm";
import WaiverForms from "./JotformWaiver";
import { AuthProvider, useAuthContext } from "@galvanize-inc/jwtdown-for-react";
import OfferingsPage from './OfferingsPage';
import EnrolledClassesPage from './EnrolledClasses';
import PurchaseTokensPage from './PurchaseTokensPage';
import PaymentConfirmationPage from './PaymentConfirmationPage';
import Profile from './Profile';
import CreateAccount from './CreateAccount';
import CreateClass from './CreateClass';
import ClassList from './ClassList';
import EditProfile from './EditProfile';
import UserList from './UserList';
import YogaSimulation from './YogaSimulation';
import Enrollments from './Enrollments';


function App() {
  const domain = /https:\/\/[^/]+/;
  const basename = process.env.PUBLIC_URL.replace(domain, '');
  const baseUrl = process.env.REACT_APP_API_HOST;

  return (
    <BrowserRouter basename={basename}>
      <AuthProvider baseUrl={baseUrl} tokenUrl={`${baseUrl}/users/token`}>
        <AppContent />
      </AuthProvider>
    </BrowserRouter>
  );
}

function AppContent() {
  const { isLoading } = useAuthContext();

  // While loading, render nothing
  if (isLoading) {
    return null;
  }

  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/waivers" element={<WaiverForm />} />
      <Route path="/waiver" element={<WaiverForms />} />
      <Route path="/login" element={<LoginForm />} />
      <Route path="/offerings" element={<OfferingsPage />} />
      <Route path="/enrolled-classes" element={<EnrolledClassesPage />} />
      <Route path="/purchase-tokens" element={<PurchaseTokensPage/>} />
      <Route path="/payment-confirmation" element={<PaymentConfirmationPage />} />
      <Route path="/profile" element={<Profile />} />
      <Route path="/signup" element={<CreateAccount/>} />
      <Route path="/create-class" element={<CreateClass />} />
      <Route path="/class-list" element={<ClassList />} />
      <Route path="/edit-profile" element={<EditProfile />} />
      <Route path="/user-list" element={<UserList />} />
      <Route path="/sim" element={<YogaSimulation />} />
      <Route path="/enrollments" element={<Enrollments />} />
      <Route path="*" element={<YogaSimulation />} />
    </Routes>
  );
}

export default App;
