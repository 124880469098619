import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import useToken from "@galvanize-inc/jwtdown-for-react";


function Navbar({ isLoggedIn }) {
  const { token } = useToken();
  const navigate = useNavigate();
  const [waiverSubmitted, setWaiverSubmitted] = useState(false);  // State to track waiver status
  const [error, setError] = useState(null);
  const [userRole, setUserRole] = useState(null);  // State to track user role

  // Fetch user profile to get waiver status and user role
  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_HOST}/user/current/`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (response.ok) {
          const userData = await response.json();
          setWaiverSubmitted(userData.is_waiver_submitted);  // Set waiver status
          setUserRole(userData.role);  // Set user role
        } else {
          setError('Failed to fetch user profile.');
        }
      } catch (err) {
        console.error("Error fetching profile:", err);
        setError('Error fetching user profile.');
      }
    };

    if (isLoggedIn) {
      fetchUserProfile();  // Fetch only if the user is logged in
    }
  }, [isLoggedIn, token]);

  // Handle logout
  const handleLogout = async (event) => {
    event.preventDefault();

    try {
      const response = await fetch(`${process.env.REACT_APP_API_HOST}/auth/token`, {
        method: "DELETE",
        credentials: "include",
      });

      if (response.ok) {
        localStorage.removeItem('token');
        navigate("/");
        window.location.reload();
      } else {
        console.error("Failed to logout.");
      }
    } catch (err) {
      console.error("Error logging out:", err);
    }
  };

  return (
    <nav className="navbar">
      <ul className="nav-links">
        <li>
          <NavLink to="/" className={({ isActive }) => (isActive ? "active-link" : "")}>Home</NavLink>
        </li>

        {waiverSubmitted ? (
          <>
            <li>
              <NavLink to="/offerings" className={({ isActive }) => (isActive ? "active-link" : "")}>Offerings</NavLink>
            </li>
            <li>
              <NavLink to="/enrolled-classes" className={({ isActive }) => (isActive ? "active-link" : "")}>Enrolled Classes</NavLink>
            </li>
            <li>
              <NavLink to="/purchase-tokens" className={({ isActive }) => (isActive ? "active-link" : "")}>Purchase Tokens</NavLink>
            </li>
          </>
        ) : (
          <li>
            <NavLink to="/waivers" className={({ isActive }) => (isActive ? "active-link" : "")}>Registration (Waiver)</NavLink>
          </li>
        )}

        <li>
          <NavLink to="/profile" className={({ isActive }) => (isActive ? "active-link" : "")}>Profile</NavLink>
        </li>

        {/* Admin and Teacher should see the Create Class and Class List links */}
        {(userRole === 1 || userRole === 2) && waiverSubmitted && (
          <>
            {/* <li>
              <NavLink to="/create-class" className={({ isActive }) => (isActive ? "active-link" : "")}>Create Class (ADMIN)</NavLink>
            </li> */}
            <li>
              <NavLink to="/class-list" className={({ isActive }) => (isActive ? "active-link" : "")}>Class List (ADMIN)</NavLink>
            </li>
          </>
        )}
        {/* Admin and Teacher should see the enrollments list link */}
        {(userRole === 1 || userRole === 2) && waiverSubmitted && (
          <>
            <li>
              <NavLink to="/enrollments" className={({ isActive }) => (isActive ? "active-link" : "")}>Enrollments (ADMIN)</NavLink>
            </li>
          </>
        )}
        {/* Admin (role=1) should see the User List link */}
        {userRole === 1 && (
          <li>
            <NavLink to="/user-list" className={({ isActive }) => (isActive ? "active-link" : "")}>User List (ADMIN)</NavLink>
          </li>
        )}

        {isLoggedIn ? (
          <li>
            <NavLink
              to="/logout"
              className={({ isActive }) => (isActive ? "active-link" : "")}
              onClick={handleLogout}
            >
              Logout
            </NavLink>
          </li>
        ) : (
          <li>
            <NavLink to="/login" className={({ isActive }) => (isActive ? "active-link" : "")}>Login</NavLink>
          </li>
        )}
      </ul>
    </nav>
  );
}

export default Navbar;
