import React, { useState, useEffect } from 'react';
import useToken from '@galvanize-inc/jwtdown-for-react';
import './Enrollments.css';
import Navbar from './Navbar';

const AllEnrollments = () => {
  const { token } = useToken();
  const [allEnrollments, setAllEnrollments] = useState([]);
  const [userRole, setUserRole] = useState(null);  // Store the user's role
  const [loading, setLoading] = useState(true);  
  const [error, setError] = useState(null);  

  useEffect(() => {
    if (token) {
      fetchUserRole();  // Fetch the user's role
      fetchAllEnrollments();
    }
  }, [token]);

  const fetchUserRole = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_HOST}/user/current/`, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
      const data = await response.json();
      setUserRole(data.role);  // Set user role
    } catch (error) {
      console.error('Error fetching user role:', error);
    }
  };

  const fetchAllEnrollments = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_HOST}/enrollments/all`, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
      const data = await response.json();
      setAllEnrollments(data);
    } catch (error) {
      console.error('Error fetching all enrollments:', error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  // Disenroll handler
  const handleDisenroll = async (enrollmentId) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_HOST}/enrollments/disenroll/${enrollmentId}`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
      if (response.ok) {
        alert('User disenrolled successfully');
        fetchAllEnrollments();  // Refresh enrollments after disenrollment
      } else {
        alert('Failed to disenroll user');
      }
    } catch (error) {
      console.error('Error disenrolling user:', error);
    }
  };

  return (
    <div className="all-enrollments-page">
      <Navbar isLoggedIn={!!token} />
      <h1>All Enrollments</h1>
      {loading ? (
        <p>Loading enrollments...</p>
      ) : error ? (
        <p>Error: {error}</p>
      ) : allEnrollments.length > 0 ? (
        <table className="enrollment-table">
          <thead>
            <tr>
              <th>First Name</th>
              <th>Last Name</th>
              <th>Class Title</th>
              <th>Date</th>
              <th>Status</th>
              <th>Actions</th> {/* Add Actions column */}
            </tr>
          </thead>
          <tbody>
            {allEnrollments.map((enrollment) => (
              <tr key={enrollment.id}>
                <td>{enrollment.first_name}</td>
                <td>{enrollment.last_name}</td>
                <td>{enrollment.class_title}</td>
                <td>{enrollment.class_date}</td>
                <td>{enrollment.status}</td>
                <td>
                  {/* Show Disenroll button for admins or teachers for their own classes */}
                  {userRole === 1 || (userRole === 2 && enrollment.instructor_id === userRole) ? (
                    enrollment.status === 'enrolled' && (
                      <button onClick={() => handleDisenroll(enrollment.id)} className="disenroll-button">
                        Disenroll
                      </button>
                    )
                  ) : null}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p>No enrollments found.</p>
      )}
    </div>
  );
};

export default AllEnrollments;
