import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import useToken from '@galvanize-inc/jwtdown-for-react';
import './PaymentConfirmationPage.css';

const PaymentConfirmationPage = () => {
    const { token } = useToken();
    const [searchParams] = useSearchParams();
    const [message, setMessage] = useState("Processing your payment..."); // Initial message
    const navigate = useNavigate();

    // Extract the paymentId and PayerID from the URL search params
    const paymentId = searchParams.get("paymentId");
    const payerId = searchParams.get("PayerID");

    useEffect(() => {
        const handlePaymentExecution = async () => {
            if (!token) {
                setMessage("Please wait while your yo-bucks are added to your account");
                return;
            }

            try {
                const url = new URL(`${process.env.REACT_APP_API_HOST}/paypal/execute`);
                url.searchParams.append("paymentId", paymentId);
                url.searchParams.append("PayerID", payerId);

                const response = await fetch(url.toString(), {
                    method: "GET",
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`, // Pass token for authentication
                    },
                });

                const data = await response.json();

                if (response.ok) {
                    setMessage("Payment successful! Your Yo-Bucks have been added to your account. One moment while we bring you back");
                    // Redirect to offerings or dashboard after success
                    setTimeout(() => {
                        navigate("/offerings");
                    }, 3000); // Delay the redirect for 3 seconds to show the message
                } else {
                    setMessage("Payment failed: " + (data.detail || "Unknown error"));
                }
            } catch (error) {
                console.error("Error executing payment:", error);
                setMessage("Error completing the payment.");
            }
        };

        // Only try to execute the payment if both paymentId and payerId are present
        if (paymentId && payerId) {
            handlePaymentExecution();
        } else {
            setMessage("Invalid payment details.");
        }
    }, [paymentId, payerId, token, navigate]);

    return (
        <div className="payment-confirmation-container">
            <p>Thank you for your support! Your Yo-Bucks have been added to your account. Please wait while we bring you back!</p> {/* Render the message */}
        </div>
    );
};

export default PaymentConfirmationPage;
