import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useToken from '@galvanize-inc/jwtdown-for-react';
import './EditProfile.css';  // CSS for the Edit Profile page
import Navbar from './Navbar';
import logo from './01logo.png';

const EditProfile = () => {
    const { token } = useToken();  // Get the token
    const [user, setUser] = useState(null);  // Store the user's current profile
    const [error, setError] = useState(null);  // For errors
    const [success, setSuccess] = useState(null);  // For success messages
    const navigate = useNavigate();

    // Fields that can be edited
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [bio, setBio] = useState("");

    // Fetch the current user's profile
    useEffect(() => {
        const fetchUserProfile = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_HOST}/user/current/`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                if (response.ok) {
                    const userData = await response.json();
                    setUser(userData);
                    setFirstName(userData.first_name);
                    setLastName(userData.last_name);
                    setEmail(userData.email);
                    setPhoneNumber(userData.phone_number || "");
                    setBio(userData.bio || "");
                } else {
                    setError('Failed to fetch user profile.');
                }
            } catch (error) {
                setError('Error fetching profile data.');
            }
        };

        if (token) {
            fetchUserProfile();
        }
    }, [token]);

    // Handle successful profile update and redirect after 1 second
    useEffect(() => {
        if (success) {
            const timer = setTimeout(() => {
                navigate('/profile');  // Redirect to profile page after 1 second
            }, 1000);
            return () => clearTimeout(timer);  // Cleanup timeout
        }
    }, [success, navigate]);

    const handleSubmit = async (event) => {
        event.preventDefault();

        try {
            const response = await fetch(`${process.env.REACT_APP_API_HOST}/user/${user.id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify({
                    first_name: firstName,
                    last_name: lastName,
                    email: email,
                    phone_number: phoneNumber,
                    bio: bio
                }),
            });

            if (response.ok) {
                setSuccess("Profile updated successfully.");
            } else {
                setError("Failed to update profile.");
            }
        } catch (error) {
            setError("An error occurred while updating the profile.");
        }
    };

    if (!user) {
        return <div>Loading profile...</div>;
    }

    return (
        <>
            <Navbar isLoggedIn={!!token} />
            <div className="edit-profile-container">
                <div className="logo-container">
                    <img src={logo} alt="Yoganautics Logo" className="logo" />
                </div>
                <h1>Edit Profile</h1>
                {error && <p className="error-message">{error}</p>}
                {success && <p className="success-message">{success}</p>}
                <form onSubmit={handleSubmit} className="edit-profile-form">
                    <label htmlFor="first-name">First Name</label>
                    <input
                        type="text"
                        id="first-name"
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                        required
                    />
                    <label htmlFor="last-name">Last Name</label>
                    <input
                        type="text"
                        id="last-name"
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                        required
                    />
                    <label htmlFor="email">Email</label>
                    <input
                        type="email"
                        id="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                    <label htmlFor="phone-number">Phone Number</label>
                    <input
                        type="tel"
                        id="phone-number"
                        value={phoneNumber}
                        onChange={(e) => setPhoneNumber(e.target.value)}
                    />
                    <label htmlFor="bio">Bio</label>
                    <textarea
                        id="bio"
                        value={bio}
                        onChange={(e) => setBio(e.target.value)}
                    />
                    <button type="submit">Save Changes</button>
                    <button type="button" className="cancel-button" onClick={() => navigate('/profile')}>
                        Cancel
                    </button>
                </form>
            </div>
        </>
    );
};

export default EditProfile;
