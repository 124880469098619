import useToken, { useAuthContext } from "@galvanize-inc/jwtdown-for-react";
import { useState, useEffect } from "react";
import { login } from "./auth";
import { useNavigate } from 'react-router-dom';
import logo from './01logo.png'; // Assuming you have the logo image imported
import './LoginForm.css';
import './logo.css';
import { FaEye, FaEyeSlash } from 'react-icons/fa'; 


const LoginForm = () => {
    const navigate = useNavigate();
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false); // State to toggle password visibility
    const [error, setError] = useState("");
    const { setToken, baseUrl } = useAuthContext();
    const { token } = useToken();

    useEffect(() => {
        // Add 'login-page' class to the body tag for login-specific styling
        document.body.classList.add('login-page');

        // Cleanup function to remove 'login-page' class when component unmounts
        return () => {
            document.body.classList.remove('login-page');
        };
    }, []);

    useEffect(() => {
        if (token) {
            navigate("/");
        }
    }, [token, navigate]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        const form = e.currentTarget;
            // Convert username to lowercase
        const lowercaseUsername = username.toLowerCase();
        try {
            const token = await login(baseUrl, lowercaseUsername, password);
            setToken(token);
            navigate("/");
        } catch (e) {
            if (e instanceof Error) {
                setError("Login Failed");
            }
            console.error(e);
        }
        form.reset();
    };

    const createAccount = async () => {
        navigate("/signup");
    };

    // Toggle the password visibility
    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    };

    return (
        <div className="login-container">
            <div className="login-card">
                {/* Logo */}
                <div className="logo-container">
                    <img src={logo} alt="Yoganautics Logo" className="logo" />
                </div>

                {/* Welcome Message */}
                <h1 className="login-welcome-message">Welcome Back to Yoganautics</h1>
                <p className="welcome-subtext">We're glad to see you again! Please log in to continue your journey.</p>

                {error ? <div className="alert alert-danger" role="alert">{error}</div> : null}

                <form onSubmit={handleSubmit} className="login-form">
                    <div className="password-field-container">
                        <input
                            autoComplete="true"
                            id="username"
                            name="username"
                            placeholder="Username"
                            type="text"
                            className="form-control"
                            onChange={(e) => setUsername(e.target.value)}
                        />
                    </div>
                    {/* Password Field with Show/Hide Toggle */}
                    <div className="password-field-container">
                        <input
                            autoComplete="true"
                            id="password"
                            name="password"
                            placeholder="Password"
                            type={showPassword ? "text" : "password"}  // Toggle between text and password
                            className="ca-input-field"  // Use consistent input field styling
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        <button
                            type="button"
                            className="show-password-btn"
                            onClick={toggleShowPassword}
                        >
                            {showPassword ? <FaEyeSlash /> : <FaEye />}  {/* Toggle eye icon */}
                        </button>
                    </div>

                    <div className="button-group">
                        <button className="btn login-btn" type="submit">
                            Login
                        </button>
                        <button className="btn create-btn" type="button" onClick={createAccount}>
                            Create Account
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default LoginForm;
