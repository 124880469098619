import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useToken from '@galvanize-inc/jwtdown-for-react';
import Navbar from './Navbar';
import './Profile.css';  // Import the CSS for profile styling
import logo from './01logo.png';
import foggybirds from './foggy-birds.png';

const Profile = () => {
    const { token } = useToken();  // Get token from hook
    const [user, setUser] = useState(null);  // User state to store profile data
    const [error, setError] = useState(null);  // Error state
    const navigate = useNavigate();  // Navigation for redirecting

    // Redirect to home if not logged in
    useEffect(() => {
        if (!token) {
            navigate('/');  // Redirect to home page if not logged in
        }
    }, [token, navigate]);
    
    // Decode the token to extract user info
    const decodedToken = token ? JSON.parse(atob(token.split('.')[1])) : null;
    const userId = decodedToken?.account?.id;  // Access the user ID safely

    useEffect(() => {
        const fetchUserProfile = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_HOST}/user/current/`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                if (response.ok) {
                    const userData = await response.json();
                    setUser(userData);
                } else {
                    setError('Failed to fetch user profile.');
                }
            } catch (error) {
                setError('Error fetching profile data.');
            }
        };

        if (userId && token) {
            fetchUserProfile();
        }
    }, [token, userId]);

    const handleEditProfile = () => {
        navigate('/edit-profile');  // Redirect to edit profile page
    };

    if (!user) {
        return <div>Loading profile...</div>;  // Show a loading message if profile isn't loaded yet
    }

    return (
        <>
            {/* Render Navbar */}
            <Navbar isLoggedIn={!!token} />
            <div className="offerings-background" style={{
                background: `linear-gradient(135deg, rgba(115, 0, 222, 0.8), rgba(172, 72, 232, 0.9)), url(${foggybirds})`,
                backgroundAttachment: 'fixed',
                backgroundPosition: 'center',
                backgroundRepeat: 'repeat',
                backgroundSize: 'auto',
             }}>
                <div className="logo-container">
                    <img src={logo} alt="Logo" className="logo" />
                </div>
                <div className="profile-container">
                    <h1>User Profile</h1>
                    {error && <p className="error-message">{error}</p>}
                    <div className="profile-info">
                        <p><strong>First Name:</strong> {user.first_name}</p>
                        <p><strong>Last Name:</strong> {user.last_name}</p>
                        <p><strong>Username:</strong> {user.username}</p>
                        <p><strong>Email:</strong> {user.email}</p>
                        <p><strong>Phone Number:</strong> {user.phone_number || 'N/A'}</p>
                        <p><strong>Tokens:</strong> {user.tokens || 0}</p>
                        <p><strong>Waiver Submitted:</strong> {user.is_waiver_submitted ? 'Yes' : 'No'}</p>
                        <p><strong>Teaching Contract Submitted:</strong> {user.is_contract_submitted ? 'Yes' : 'No'}</p>
                        <button className="edit-profile-button" onClick={handleEditProfile}>
                            Edit Profile
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Profile;
