import React, { useEffect, useState } from "react";
import useToken from "@galvanize-inc/jwtdown-for-react";
// import { useNavigate } from "react-router-dom";  // Unused for now, but could be used for redirects

// Base URL for API (Ensure this is correctly set in the environment)
const API_BASE_URL = process.env.REACT_APP_API_HOST;

function WaiverForm() {
    const { token } = useToken(); // Get the JWT token for authorization
    // const navigate = useNavigate();  // Can be used for navigation if needed
    const [hasActiveWaiver, setHasActiveWaiver] = useState(null); // Track waiver status (null means loading)
    const [activeWaiverId, setActiveWaiverId] = useState(null);   // Store the ID of the active waiver (if any)
    const [error, setError] = useState("");  // Error state for displaying messages

    // This effect runs on component mount to check the user's waiver status
    useEffect(() => {
        // Function to check the waiver status for the logged-in user
        const checkWaiverStatus = async () => {
            if (!API_BASE_URL) {
                setError("API base URL is not configured.");
                return;
            }

            try {
                // Fetch the waiver status for the logged-in user
                const response = await fetch(`${API_BASE_URL}/waivers/status`, {
                    method: "GET",
                    credentials: "include",  // Include credentials (cookie-based auth, if applicable)
                    headers: {
                        "Content-Type": "application/json",  // Indicate we're working with JSON
                        Authorization: `Bearer ${token}`,    // Include the JWT token for authorization
                    },
                });

                // Handle successful response
                if (response.ok) {
                    const data = await response.json();  // Parse JSON response

                    // Check if the response is an array (multiple waivers) or a single object
                    const waivers = Array.isArray(data) ? data : [data];

                    // Find an active waiver where `revoked_at` is null (i.e., not revoked)
                    const activeWaiver = waivers.find(waiver => waiver.revoked_at === null);

                    // If an active waiver is found, update the state
                    if (activeWaiver) {
                        setHasActiveWaiver(true);
                        setActiveWaiverId(activeWaiver.id); // Store the ID for future revocation
                    } else {
                        setHasActiveWaiver(false); // No active waiver found
                    }
                } else {
                    setError("Failed to retrieve waiver status."); // Handle non-200 status
                }
            } catch (error) {
                // Handle any network or unexpected errors
                console.error("Error fetching waiver status:", error);
                setError("An error occurred while checking waiver status.");
            }
        };

        // Check waiver status only if the token is available (user is logged in)
        if (token) {
            checkWaiverStatus();
        }
    }, [token]);  // Re-run the effect if the token changes

    // Function to handle waiver revocation
    const handleRevokeWaiver = async () => {
        try {
            // Send a PUT request to revoke the waiver (by waiver ID)
            const response = await fetch(`${API_BASE_URL}/waivers/${activeWaiverId}`, {
                method: "PUT",  // Revocation is done via PUT
                credentials: "include",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,  // Include the JWT token
                },
            });

            // If revocation is successful, update the state to allow a new waiver submission
            if (response.ok) {
                setHasActiveWaiver(false); // Allow a new waiver to be submitted
            } else {
                setError("Failed to revoke the waiver.");  // Handle non-200 status
            }
        } catch (error) {
            // Handle any network or unexpected errors
            console.error("Error revoking waiver:", error);
            setError("An error occurred while revoking the waiver.");
        }
    };

    // Render the waiver form (JotForm embed) for users without an active waiver
    const renderWaiverForm = () => (
        <div className="card w-75 p-4">
            <h1 className="text-center mb-4">Submit Your Waiver</h1>
            {/* Embed the JotForm for submitting waivers */}
            <div className="jotform-embed">
                <iframe
                    title="Waiver Form"
                    src="https://form.jotform.com/242693910156156"  // Replace with your actual JotForm URL
                    width="100%"  // Make the iframe full-width
                    height="1000px"  // Set the height to display the form properly
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                ></iframe>
            </div>
        </div>
    );

    // Render the revoke button for users with an active waiver
    const renderRevokeButton = () => (
        <div className="card w-50 p-4 text-center">
            <h1>You already have an active waiver.</h1>
            <button
                className="btn btn-danger mt-4"
                onClick={handleRevokeWaiver}  // On click, revoke the active waiver
            >
                Revoke Waiver
            </button>
        </div>
    );

    // If no token is found, prompt the user to log in (protect this route)
    if (!token) {
        return (
            <div className="container d-flex justify-content-center align-items-center vh-100">
                <div className="card w-50 p-4">
                    <h1>Waiver Form</h1>
                    <p>You must be logged in to view this page.</p>
                </div>
            </div>
        );
    }

    // Render the page depending on the user's waiver status
    return (
        <div className="container d-flex justify-content-center align-items-center vh-100">
            {/* Show loading state while waiver status is being checked */}
            {hasActiveWaiver === null && <p>Loading...</p>}

            {/* Show the waiver form if no active waiver exists */}
            {hasActiveWaiver === false && renderWaiverForm()}

            {/* Show the revoke button if an active waiver exists */}
            {hasActiveWaiver === true && renderRevokeButton()}

            {/* Display any errors */}
            {error && <p style={{ color: "red" }}>{error}</p>}
        </div>
    );
}

export default WaiverForm;
