import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useToken, { useAuthContext } from "@galvanize-inc/jwtdown-for-react";
import Navbar from './Navbar';  // Import Navbar for logged-in users
import './CreateAccount.css';  // Import the CSS for styling
import logo from './01logo.png';  // Assuming you have a logo
import foggybirds from './foggy-birds.png';
import { FaEye, FaEyeSlash } from 'react-icons/fa';  // For the eyeball icon

const CreateAccount = () => {
    const { token } = useToken();  // Properly retrieve token here
    const { setToken, baseUrl } = useAuthContext();
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        first_name: '',
        last_name: '',
        username: '',
        email: '',
        password: '',
        phone_number: '',
    });
    const [userRole, setUserRole] = useState(null);  // State for user role
    const [error, setError] = useState('');
    const [showPassword, setShowPassword] = useState(false);  // State for showing/hiding password

    // Decode the token to extract user role and info
    const decodedToken = token ? JSON.parse(atob(token.split('.')[1])) : null;
    const userRoleFromToken = decodedToken?.account?.role;  // Access the role from the token

    // Set the user role when the component mounts
    useEffect(() => {
        if (userRoleFromToken) {
            setUserRole(userRoleFromToken);
        }
    }, [userRoleFromToken]);

    // Toggle the password visibility
    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;

        // Auto-format phone number
        if (name === "phone_number") {
            setFormData({
                ...formData,
                [name]: formatPhoneNumber(value),
            });
        } else {
            setFormData({
                ...formData,
                [name]: value,
            });
        }
    };

    const formatPhoneNumber = (value) => {
        // Remove all non-numeric characters
        const cleaned = ('' + value).replace(/\D/g, '');

        // Limit the input to 10 digits
        if (cleaned.length > 10) {
            return value.slice(0, -1);
        }

        // Format as (xxx) xxx-xxxx
        const match = cleaned.match(/^(\d{0,3})(\d{0,3})(\d{0,4})$/);

        if (match) {
            if (match[2]) {
                return `(${match[1]}) ${match[2]}${match[3] ? `-${match[3]}` : ''}`;
            } else if (match[1]) {
                return `(${match[1]}`;
            }
        }

        return value;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
    // Convert username to lowercase
        const lowerCaseFormData = {
            ...formData,
            username: formData.username.toLowerCase(),  // Ensure username is in lowercase
        };
        try {
            const response = await fetch(`${process.env.REACT_APP_API_HOST}/user`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(lowerCaseFormData),
            });

            if (response.ok) {
                if (userRole === 1) {
                    // If admin, redirect to user list after creating an account
                    navigate("/users/list");
                } else {
                    // Auto-login if the user is not admin
                    await handleAutoLogin();
                }
            } else {
                const errorData = await response.json();
                setError(errorData.detail || 'Account creation failed');
            }
        } catch (err) {
            console.error('Error creating account:', err);
            setError('An unexpected error occurred.');
        }
    };

    const handleAutoLogin = async () => {
        try {
            const lowerCaseUsername = formData.username.toLowerCase();
            // Log the user in with the username and password they just created
            const loginResponse = await fetch(`${baseUrl}/token`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    username: lowerCaseUsername,
                    password: formData.password,
                }),
            });

            if (loginResponse.ok) {
                const { access_token } = await loginResponse.json();
                setToken(access_token);  // Store the token in context
                navigate("/waivers");  // Redirect to the homepage
            } else {
                setError("Auto-login failed. Please log in manually.");
            }
        } catch (err) {
            console.error("Auto-login error:", err);
            setError("Error logging in. Please try again.");
        }
    };

    // If logged in and not admin, show "already logged in" message
    if (token && userRole !== 1) {
        return (
            <>
                <Navbar isLoggedIn={!!token} />
                <div className="already-logged-in-container">
                    <h1>You are already logged in.</h1>
                    <p>If you want to create a new account, please log out first.</p>
                </div>
            </>
        );
    }

    return (
        <div className="create-account-page">
            <Navbar isLoggedIn={!!token} />
            <div className="create-account-background">
                <div className="logo-container">
                    <img src={logo} alt="Yoganautics Logo" className="logo" />
                </div>
                <div className="create-account-container">
                    <h1 className="account-title">{userRole === 1 ? "ADMIN CREATING NEW USER" : "Join Yoganautics - Your Journey Begins Here!"}</h1>
                    <p className="welcome-message">
                        At Yoganautics, we believe that every journey to inner peace and personal growth begins with a single step. 
                        Creating an account with us is your first step toward a transformative yoga experience. 
                        Unlock access to classes that rejuvenate your mind, body, and spirit. Start your journey to becoming a true Yoganaut today!
                    </p>
                    {error && <div className="alert alert-danger">{error}</div>}
                    <form onSubmit={handleSubmit} className="create-account-form">
                        <input
                            type="text"
                            name="first_name"
                            placeholder="First Name"
                            value={formData.first_name}
                            onChange={handleChange}
                            required
                            className="ca-input-field"
                        />
                        <input
                            type="text"
                            name="last_name"
                            placeholder="Last Name"
                            value={formData.last_name}
                            onChange={handleChange}
                            required
                            className="ca-input-field"
                        />
                        <input
                            type="text"
                            name="username"
                            placeholder="Username"
                            value={formData.username}
                            onChange={handleChange}
                            required
                            className="ca-input-field"
                        />
                        <input
                            type="email"
                            name="email"
                            placeholder="Email"
                            value={formData.email}
                            onChange={handleChange}
                            required
                            className="ca-input-field"
                        />
                        <div className="password-field-container">
                            <input
                                type={showPassword ? "text" : "password"}
                                name="password"
                                placeholder="Password"
                                value={formData.password}
                                onChange={handleChange}
                                required
                                className="ca-input-field"
                            />
                            <button type="button" className="show-password-btn" onClick={toggleShowPassword}>
                                {showPassword ? <FaEyeSlash /> : <FaEye />}
                            </button>
                        </div>
                        <input
                            type="tel"
                            name="phone_number"
                            placeholder="Phone Number"
                            value={formData.phone_number}
                            onChange={handleChange}
                            required
                            className="ca-input-field"
                        />
                        <button type="submit" className="create-btn">Create Account</button>
                    </form>
                </div>
            </div>
        </div>
    );    
};

export default CreateAccount;
