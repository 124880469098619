import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useToken from '@galvanize-inc/jwtdown-for-react';
import Navbar from './Navbar'; // Assuming you have a Navbar component
import './CreateClass.css'; // Dashboard style for the form

const CreateClass = () => {
  const { token, isLoading } = useToken();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    title: '',
    description: '',
    instructor_pay: 25.00, // Default instructor pay value
    class_date: '',
    class_time: '',
    duration: 0,
    level: '',
    capacity: 0,
    tokens_required: 1,
    instructor_id: 0
  });
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userRole, setUserRole] = useState(null);
  const [instructors, setInstructors] = useState([]);
  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {
    if (!isLoading) {
      setIsLoggedIn(!!token);
    }
  }, [token, isLoading]);

  useEffect(() => {
    const fetchUserRoleAndInstructors = async () => {
      if (token) {
        try {
          // Fetch the current user's details
          const response = await fetch(`${process.env.REACT_APP_API_HOST}/user/current/`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`,
            },
          });
          const data = await response.json();

          if (response.ok) {
            setUserRole(data.role);
            setCurrentUser(data); // Store current user details

            if (data.role === 1) {
              // If the user is admin, fetch all users with admin (role 1) and teacher (role 2)
              const usersResponse = await fetch(`${process.env.REACT_APP_API_HOST}/user/?roles=1,2`, {
                method: 'GET',
                headers: {
                  'Authorization': `Bearer ${token}`,
                },
              });
              const usersData = await usersResponse.json();

              if (usersResponse.ok) {
                setInstructors(usersData); // Populate instructors for admin dropdown
              }
            } else if (data.role === 2) {
              // If the user is a teacher, preselect the current user as the instructor
              setFormData((prevData) => ({
                ...prevData,
                instructor_id: data.id,
              }));
            }
          } else {
            alert('Error fetching user role');
          }
        } catch (error) {
          console.error('Error fetching user data:', error);
        }
      }
    };

    fetchUserRoleAndInstructors();
  }, [token]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(`${process.env.REACT_APP_API_HOST}/classes`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify(formData),
      });

      const result = await response.json();
      if (response.ok) {
        alert('Class created successfully!');
        navigate('/class-list'); // Redirect after success
      } else {
        alert(`Failed to create class: ${result.message}`);
      }
    } catch (error) {
      console.error('Error creating class:', error);
    }
  };

  if (isLoading || userRole === null) {
    return <div>Loading...</div>; // Show loading state
  }

  return (
    <div className="create-class-page">
      <Navbar isLoggedIn={!!token} /> {/* Navbar consistent with the HomePage */}
      <div className="create-class-container">
        <h1>Create a New Class</h1>
        <form onSubmit={handleSubmit} className="create-class-form">
          <div className="form-group">
            <label htmlFor="title">Class Title</label>
            <input
              type="text"
              name="title"
              value={formData.title}
              onChange={handleChange}
              required
              className="cc-input-field"
            />
          </div>

          <div className="form-group">
            <label htmlFor="description">Description</label>
            <textarea
              name="description"
              value={formData.description}
              onChange={handleChange}
              className="cc-input-field"
            />
          </div>

          <div className="form-group">
            <label htmlFor="class_date">Class Date</label>
            <input
              type="date"
              name="class_date"
              value={formData.class_date}
              onChange={handleChange}
              required
              className="cc-input-field"
            />
          </div>

          <div className="form-group">
            <label htmlFor="class_time">Class Time</label>
            <input
              type="time"
              name="class_time"
              value={formData.class_time}
              onChange={handleChange}
              required
              className="cc-input-field"
            />
          </div>

          <div className="form-group">
            <label htmlFor="duration">Duration (minutes)</label>
            <input
              type="number"
              name="duration"
              value={formData.duration}
              onChange={handleChange}
              required
              className="cc-input-field"
            />
          </div>

          <div className="form-group">
            <label htmlFor="level">Level</label>
            <input
              type="text"
              name="level"
              value={formData.level}
              onChange={handleChange}
              className="cc-input-field"
            />
          </div>

          <div className="form-group">
            <label htmlFor="capacity">Capacity</label>
            <input
              type="number"
              name="capacity"
              value={formData.capacity}
              onChange={handleChange}
              required
              className="cc-input-field"
            />
          </div>

          {/* Conditionally render the instructor dropdown or prefilled input */}
          <div className="form-group">
            <label htmlFor="instructor_id">Instructor</label>
            {userRole === 1 ? (
              <select
                name="instructor_id"
                value={formData.instructor_id}
                onChange={handleChange}
                required
                className="cc-input-field"
              >
                <option value="">Select Instructor</option>
                {instructors
                  .filter((instructor) => instructor.role === 1 || instructor.role === 2)
                  .map((instructor) => (
                    <option key={instructor.id} value={instructor.id}>
                      {`${instructor.first_name} ${instructor.last_name} (${instructor.role === 1 ? 'Admin' : 'Teacher'})`}
                    </option>
                  ))}
              </select>
            ) : (
              <input
                type="text"
                value={`${currentUser?.first_name} ${currentUser?.last_name}`}
                disabled
                className="cc-input-field"
              />
            )}
          </div>

          <div className="form-group">
            <label htmlFor="tokens_required">Tokens Required</label>
            <input
              type="number"
              name="tokens_required"
              value={formData.tokens_required}
              onChange={handleChange}
              required
              className="cc-input-field"
            />
          </div>

          <button type="submit" className="submit-button">Create Class</button>
        </form>
      </div>
    </div>
);

};

export default CreateClass;
