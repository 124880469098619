import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useToken from '@galvanize-inc/jwtdown-for-react';
import './HomePage.css';
import './logo.css';
import Navbar from './Navbar';
import logo from './01logo.png'; // Path to your logo
import mountains from './mountains.jpg'; // Path to your mountain image

const HomePage = () => {
  const { token, isLoading } = useToken();
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [firstName, setFirstName] = useState('');

  // Extract user information from the token
  useEffect(() => {
    if (token) {
      try {
        const decodedToken = token ? JSON.parse(atob(token.split('.')[1])) : null;
        const firstName = decodedToken?.account?.first_name;
        if (firstName) {
          setFirstName(firstName);
        }
      } catch (error) {
        console.error("Error decoding token:", error);
      }
    }
  }, [token]);

  useEffect(() => {
    if (!isLoading) {
      setIsLoggedIn(!!token);
    }
  }, [token, isLoading]);

  if (isLoading) {
    return null;
  }

  const renderLoggedInHomePage = () => (
    <div className="home-container">
      <div className="overlay_mountain" style={{ backgroundImage: `url(${mountains})` }}>
        {/* Logo at the top */}
        <div className="logo-container">
          <img src={logo} alt="Yoganautics Logo" className="logo" />
        </div>
        {/* Hero Section with background image */}
        <div className="hero-section">
          <div className="overlay">
            <h2 className="greeting">We are glad to see you, {firstName}!</h2> {/* Personalized greeting */}
            <h1 className="home-h1">Uncover Possible</h1>
            <p className="home-p">
            Unlock your cosmic flow! Blend yoga, fitness, and ancient vibes to boost your body and chill your mind. Dive in now—your journey to a radiant, balanced you starts here.
            </p>
          </div>
        </div>
      </div>

      {/* Navigation Bar */}
      <Navbar isLoggedIn={!!token} />
      {/* Mission Section */}
      <section className="mission-section">
        <h2>Our Mission</h2>
        <p>
          At Yoganautics, we strive to provide a holistic approach to yoga and mindfulness, 
          empowering individuals to achieve a balance of mind, body, and spirit.
        </p>
      </section>

      {/* Why Yoganautics Section */}
      <section className="about-section">
        <h2>Why Yoganautics?</h2>
        <p>
          We offer personalized classes, community support, and a path to 
          discover the true essence of yoga, helping you reach your fullest potential.
        </p>
      </section>
    </div>
  );

  const renderLoggedOutCTA = () => (
    <div className="cta-container">
      <div className="logo-container">
        <img src={logo} alt="Yoganautics Logo" className="logo" />
      </div>
      <div className="discover-container">
        <h1>Discover Yoganautics</h1>
        <p>
          Take charge of your time and space with Yoganautics. Join us for traditional yoga teachings, fitness, and mindfulness practices.
        </p>
        <div className="cta-buttons">
          <button className="login-button" onClick={() => navigate('/login')}>
            Login
          </button>
          <button className="signup-button" onClick={() => navigate('/signup')}>
            Create Account
          </button>
        </div>
      </div>
    </div>
  );

  return (
    <div className="home-page">
      {isLoggedIn ? renderLoggedInHomePage() : renderLoggedOutCTA()}
    </div>
  );
};

export default HomePage;
