import React, { useState, useEffect } from 'react';
import Navbar from './Navbar';
import useToken from '@galvanize-inc/jwtdown-for-react';
import './EnrolledClasses.css';
import foggybirds from './foggy-birds.png';
import logo from './01logo.png';

const EnrolledClassesPage = () => {
    const { token } = useToken();
    const [enrolledClasses, setEnrolledClasses] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true); // Add loading state

    // Extract user info from token
    const decodedToken = token ? JSON.parse(atob(token.split('.')[1])) : null; 
    const userId = decodedToken?.account?.id; 

    useEffect(() => {
        console.log("Fetching enrolled classes..."); // Debugging fetch start
        const fetchEnrolledClasses = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_HOST}/enrollments/`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });

                console.log("Fetch response status:", response.status); // Log fetch status
                if (response.ok) {
                    const data = await response.json();
                    console.log("Fetched data:", data); // Log fetched data

                    // Filter for current or future classes only
                    const filteredClasses = data.filter(cls => {
                        const classDate = new Date(cls.class_date);
                        const today = new Date();
                        today.setHours(0, 0, 0, 0); // Set time to 00:00 to compare just the date
                        console.log("Class Date:", classDate, "Today:", today); // Log comparison details
                        return classDate >= today; // Only future or today’s classes
                    });

                    console.log("Filtered Classes:", filteredClasses); // Log filtered results

                    setEnrolledClasses(filteredClasses);
                } else {
                    setError('Failed to fetch enrolled classes.');
                    console.error('Error response from server:', response);
                }
            } catch (error) {
                setError('Error fetching enrolled classes.');
                console.error('Error during fetch:', error); // Log any caught errors
            } finally {
                setLoading(false); // Stop loading after fetch attempt
                console.log("Loading state set to false"); // Log loading state change
            }
        };

        if (token) {
            fetchEnrolledClasses();
        }
    }, [token]);

    // Time conversion helper function
    const formatTime = (timeString) => {
        let [hours, minutes] = timeString.split(':');
        hours = parseInt(hours);
        const ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12 || 12; // Convert 24-hour to 12-hour format
        return `${hours}:${minutes} ${ampm}`;
    };

    // Handle disenrollment
    const handleDisenroll = async (classId) => {
        console.log("Disenrolling from class with ID:", classId); // Log disenrollment attempt
        if (!userId || !classId) {
            setError('Missing user ID or class ID');
            console.error('Disenrollment error - Missing ID:', userId, classId); // Log missing IDs
            return;
        }

        try {
            const response = await fetch(`${process.env.REACT_APP_API_HOST}/enrollments/disenroll`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify({
                    class_id: classId,
                    user_id: userId,
                }),
            });

            if (response.ok) {
                console.log("Disenrollment successful"); // Log success
                setEnrolledClasses((prev) => prev.filter((cls) => cls.class_id !== classId));
                alert('Disenrolled successfully');
            } else {
                setError('Failed to disenroll from class.');
                console.error('Disenrollment failed:', response); // Log failure
            }
        } catch (error) {
            setError('Error disenrolling from class.');
            console.error('Error during disenrollment:', error); // Log caught errors
        }
    };

    return (
        <div>
          {/* Render Navbar */}
          <Navbar isLoggedIn={!!token} />
          <div className="offerings-background" style={{
            background: `linear-gradient(135deg, rgba(115, 0, 222, 0.8), rgba(172, 72, 232, 0.9)), url(${foggybirds})`,
            backgroundAttachment: 'fixed',
            backgroundPosition: 'center',
            backgroundRepeat: 'repeat',
            backgroundSize: 'auto',
          }}>
            <div className="logo-container">
              <img src={logo} alt="Logo" className="logo" />
            </div>
            {/* Enrolled Classes Section */}
            <div className="enrolled-classes-container">
              <h1>Your Enrolled Classes</h1>
              {error && <p className="error-message">{error}</p>}
              {loading ? (
                <p>Loading classes...</p>
              ) : (
                <>
                  {console.log("Rendered classes:", enrolledClasses)} {/* Log rendered classes */}
                  {/* Show the thank you message when no classes are found */}
                  {enrolledClasses.length === 0 && (
                    <p className="not-enrolled-message">
                      Thank you for being with us! We look forward to seeing you in future classes. Consider enrolling today to continue your journey!
                    </p>
                  )}
      
                  {/* Only render the class list if there are enrolled classes */}
                  {enrolledClasses.length > 0 && (
                    <ul className="enrolled-classes-list">
                      {enrolledClasses
                        .filter(cls => cls.status !== 'disenrolled')
                        .map((cls) => (
                          <li key={cls.class_id}>
                            <h3>{cls.class_title}</h3>
                            <h2>Class Date: {cls.class_date}</h2>
                            <h2>Class Time: {formatTime(cls.class_time.slice(0, -3))} MST</h2>
                            <p>Location: {cls.class_location}</p> {/* Show the class location */}
                            <p>Status: {cls.status}</p>
                            <p>Enrolled At: {new Date(cls.enrolled_at).toLocaleString()}</p>
                            {cls.status === 'enrolled' && (
                              <button className="disenroll-button" onClick={() => handleDisenroll(cls.class_id)}>
                                Disenroll
                              </button>
                            )}
                          </li>
                        ))}
                    </ul>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
    );
}

export default EnrolledClassesPage;
