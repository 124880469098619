import React, { useState, useRef, useEffect } from "react";
import SignatureCanvas from "react-signature-canvas";
import { useNavigate } from "react-router-dom";
import useToken from "@galvanize-inc/jwtdown-for-react";
import './WaiverForm.css';
import './logo.css'; // Custom CSS for styling the waiver form
import Navbar from './Navbar';  // Import the Navbar component
import logo from './01logo.png'; // Assuming you have a logo image
import foggybirds from './foggy-birds.png';

// Base URL for API (Ensure this is correctly set in the environment)
const API_BASE_URL = process.env.REACT_APP_API_HOST;

const waiverText = `
 Unconditional Waiver and Release
I, the undersigned, understand and agree to the following:
 Eligibility and Age Requirement.

I acknowledge that Yoganautics classes are only available to participants who are 18 years of age or older. I confirm that I am at least 18 years old, and I understand that minors are not permitted to participate in any Yoganautics Fitness, LLC classes, events, or related activities under any circumstances at this time.

 

Assumption of Risk.

I understand that participating in Yoganautics Fitness, LLC classes involves engaging in physical activity that may be strenuous and could potentially result in injury, harm, or discomfort. I am fully aware of the nature of physical fitness practice, which may include stretching, balance exercises, breathing techniques, and movement that require physical exertion.

 

Medical Considerations and Responsibility.

I affirm that it is my sole responsibility to consult with a licensed physician or healthcare provider before participating in any Yoganautics class. I understand that this consultation is to ensure that I am medically, physically, and mentally fit to safely engage in yoga activities.By signing this waiver, I represent and warrant that I am in good physical health and have no conditions, impairments, diseases, or injuries that would prevent my safe participation in any Yoganautics Fitness, LLC classes. Should any medical conditions or concerns arise, I commit to immediately disengage with the activity and inform the class instructor before the start of any session.

 

Non-Healthcare Provider Statement.

I acknowledge that Yoganautics Fitness, LLC, its instructors, and any guest instructors are not licensed healthcare providers. Any statements, suggestions, or guidance offered during the class are for informational and educational purposes only, and are not to be construed as medical advice, diagnosis, treatment, or prescription. Participants are encouraged to seek professional healthcare advice for any medical or health-related concerns.

 

Personal Responsibility for Risks and Injuries.

I accept full responsibility for any risks, injuries, or damages, whether known or unknown, that I may sustain as a result of participating in Yoganautics Fitness, LLC classes or advice from any employees or volunteers representing Yoganautics Fitness, LLC. This includes, but is not limited to, injuries arising from physical movements, improper use of equipment or props, failure to follow instructions, and conditions of the class environment.I understand that it is my responsibility to refrain from participation and inform the instructor of any injuries, physical limitations, or pre-existing medical conditions (such as pregnancy, surgeries, heart conditions, joint problems, etc.) prior to the beginning of each class, so appropriate modifications can be suggested.

 

Health Screening Prior to Class.

I agree to self-screen for any symptoms of illness, injury, or health conditions prior to each Yoganautics Fitness, LLC class. I understand that if I exhibit any symptoms or conditions that may put myself or others at risk, I will not attend the class until I am deemed fit to participate.

 

Waiver of First Aid and Medical Assistance.

I understand that Yoganautics Fitness, LLC, its instructors, and any guest instructors are not trained to provide medical care or first aid. In the event of an injury, illness, or emergency, I acknowledge that instructors are not obligated to provide first aid assistance. It is my responsibility to seek appropriate medical care if needed. I agree that Yoganautics, its instructors, and any guest instructors are not liable for any injuries or conditions resulting from lack of first aid or emergency response.

 

Release of Liability.

In consideration of being permitted to participate in Yoganautics Fitness, LLC classes, I hereby release, waive, discharge, and covenant not to sue Yoganautics Fitness, LLC, its instructors, any guest instructors, owners, or employees for any claims of liability or damages arising from personal injury, accidents, or loss that may occur as a result of my participation in the classes. I Understand and acknowledge above.


Waiver of Liability for Third-Party Locations.

I understand that some Yoganautics classes may be held in third-party venues or rented spaces. I release and discharge Yoganautics Fitness, LLC, its instructors, and any associated entities from any liability arising from conditions or incidents at such venues. I Understand and acknowledge above.


Right to Refuse Service.

Yoganautics Fitness, LLC and its instructors reserve the right to refuse or discontinue my participation in a class for any reason deemed necessary for the safety, well-being, or best interest of myself, other participants, or the instructors. I Understand and acknowledge above.
Emergency Contact Information. I understand that it is my responsibility to provide an emergency contact for notification purposes in the case of any incident during Yoganautics classes. In such an event, I consent to Yoganautics Fitness, LLC reaching out to my designated emergency contact. I Understand and acknowledge above.


Personal Belongings.

I acknowledge and agree that Yoganautics Fitness, LLC, its instructors, and any guest instructors are not liable or responsible for the safekeeping of my personal property. I understand that it is my responsibility to keep track of my personal items and that I am solely responsible for any lost, stolen, or damaged belongings during class attendance. I Understand and acknowledge above.


Conduct and Class Etiquette.

I agree to adhere to all rules, policies, and guidelines governing Yoganautics Fitness, LLC classes. I will conduct myself in a respectful manner towards instructors, staff, and fellow students, fostering a positive and safe learning environment for everyone.I understand that failure to comply with class rules or displaying disruptive behavior may result in being asked to leave the class or the program without a refund. I Understand and acknowledge above.


Media and Promotional Consent.

I grant Yoganautics permission to capture and use my name, image, likeness, and/or voice in any photographs, videos, or other materials related to Yoganautics classes. This use will be limited to internal business purposes such as training, marketing materials, or private promotional efforts within Yoganautics' direct audience.I understand that my likeness will not be shared publicly or used in external promotional materials (e.g., social media, public advertisements) without my explicit and separate consent for such public use. I Understand and acknowledge above.


Clarification on Instructor’s Role in Adjustments.

I consent to physical adjustments or corrections by Yoganautics Fitness, LLC instructors during the course of yoga classes for the purpose of alignment, safety, and technique. I understand that if I am uncomfortable with or prefer not to receive physical adjustments, it is my responsibility to inform the instructor prior to or during the class. I Understand and acknowledge above.


Duration of Waiver.

This waiver and release shall remain effective indefinitely from the date of signing unless I provide written notice requesting its revocation. Any revocation of this waiver must be submitted in writing and will only apply to future participation and media use. I Understand and acknowledge above.


Right to Modify Terms and Policies.

Yoganautics Fitness, LLC reserves the right to modify the terms of this waiver and any associated class policies with reasonable notice to participants. I understand that continued participation in Yoganautics Fitness, LLC classes after such changes indicates my acceptance of the revised terms. I Understand and acknowledge above.


Severability Clause.

This waiver and release constitutes the entire understanding between Yoganautics and myself with respect to participation in classes. If any provision is found to be invalid or unenforceable, the remaining provisions shall remain in full force and effect. I Understand and acknowledge above.

 

Jurisdiction and Governing Law.
I agree that any disputes arising from or related to this waiver, my participation in Yoganautics Fitness, LLC classes, events, or activities, shall be governed by and construed in accordance with the laws of the State of Arizona. I further agree that any legal action or proceedings shall be brought exclusively in the courts located in Phoenix, Arizona, and I consent to the jurisdiction of such courts, waiving any objections based on venue or inconvenient forum. I understand and acknowledge above.


Understanding and Acceptance.

By signing below, whether electronically or in person, I confirm that I have carefully read and fully understand the terms of this waiver and release. I understand that this is a legally binding document, and I agree to all terms voluntarily, without any inducement or coercion. My participation in Yoganautics Fitness, LLC classes is voluntary, and I acknowledge the potential risks involved.


`;
const formatPhoneNumber = (value) => {
  // Remove all non-numeric characters
  const cleaned = ('' + value).replace(/\D/g, '');

  // Limit the input to 10 digits
  if (cleaned.length > 10) {
      return value.slice(0, -1);
  }

  // Format as (xxx) xxx-xxxx
  const match = cleaned.match(/^(\d{0,3})(\d{0,3})(\d{0,4})$/);

  if (match) {
      if (match[2]) {
          return `(${match[1]}) ${match[2]}${match[3] ? `-${match[3]}` : ''}`;
      } else if (match[1]) {
          return `(${match[1]}`;
      }
  }

  return value;
};

function WaiverForm() {
  const { token } = useToken();
  const [userId, setUserId] = useState(null);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [hasActiveWaiver, setHasActiveWaiver] = useState(false);
  const [error, setError] = useState("");
  const [emergencyContactPhone, setEmergencyContactPhone] = useState(""); // State for phone input
  const sigPad = useRef();
  const navigate = useNavigate();
  const [typedName, setTypedName] = useState(""); // Name typed by the user

  // Extract user info from the token
  useEffect(() => {
    if (token) {
      try {
        const decodedToken = token ? JSON.parse(atob(token.split('.')[1])) : null;
        const userId = decodedToken?.account?.id;
        const firstName = decodedToken?.account?.first_name;
        const lastName = decodedToken?.account?.last_name;

        if (userId) {
          setUserId(userId);
          setFirstName(firstName);
          setLastName(lastName);
        } else {
          setError("Failed to decode user information.");
        }
      } catch (error) {
        console.error("Error decoding token:", error);
        setError("Failed to decode user information.");
      }
    }
  }, [token]);

  // Fetch current waiver status
  useEffect(() => {
    const fetchWaiverStatus = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/waivers/status`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          throw new Error("Failed to fetch waiver status.");
        }

        const data = await response.json();
        setHasActiveWaiver(data === true); // Check if waiver is active
      } catch (error) {
        console.error("Error fetching waiver status:", error);
      }
    };

    if (userId) {
      fetchWaiverStatus();
    }
  }, [userId, token]);

  // Clear the signature pad
  const clearSignature = () => {
    sigPad.current.clear();
  };

  // Handle phone number formatting
  const handlePhoneChange = (e) => {
    const formattedPhone = formatPhoneNumber(e.target.value);
    setEmergencyContactPhone(formattedPhone);
  };

  // Validate form input
  const validateForm = (emergencyContactName, emergencyContactPhone, signatureData) => {
    if (!emergencyContactName) return "Emergency contact name is required.";
    if (!emergencyContactPhone) return "Emergency contact phone is required.";
    if (!signatureData || sigPad.current.isEmpty()) return "Signature is required.";
    if (typedName.trim() !== `${firstName} ${lastName}`) return "The typed name must match your full name on your profile.";
    return null;  // No errors
  };

  // Handle form submission
  const handleSubmit = async (event) => {
    event.preventDefault();
    const emergencyContactName = event.target.emergencyContactName.value;
    const signatureData = sigPad.current.getTrimmedCanvas().toDataURL("image/png");

    // Validate the form inputs
    const validationError = validateForm(
      emergencyContactName, 
      emergencyContactPhone, 
      signatureData
    );
    if (validationError) {
      setError(validationError);
      return;
    }

    try {
      const response = await fetch(`${API_BASE_URL}/waivers`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          user_id: userId,
          emergency_contact_name: emergencyContactName,
          emergency_contact_phone: emergencyContactPhone,
          signature_image: signatureData,
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        setError(errorData.detail || "Failed to submit waiver.");
      } else {
        alert("Waiver submitted successfully!");
        navigate("/");
      }
    } catch (err) {
      console.error("Error submitting waiver:", err);
      setError("An unexpected error occurred.");
    }
  };

  // If waiver is already submitted, show a thank you message
  if (hasActiveWaiver) {
    return (
      <>
        <Navbar isLoggedIn={!!token} />
        <div className="waiver-background" style={{
          backgroundColor: '#7300de',
          backgroundImage: `url(${foggybirds})`,
          backgroundAttachment: 'fixed',
          backgroundPosition: 'center',
          backgroundRepeat: 'repeat',
          backgroundSize: 'auto',
          backgroundBlendMode: 'overlay',
          minHeight: '100vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}>
          <div className="logo-container">
            <img src={logo} alt="Logo" className="logo" />
          </div>
          <div className="waiver-form-container">
            <h1>Thank you! Your waiver has already been submitted.</h1>
            <p>Your waiver is on file. If you need to update it or revoke it, please contact support.</p>
            <button onClick={() => navigate("/")}>Back to Home</button>
          </div>
        </div>
      </>
    );
  }

  // Render the waiver form
  return (
    <>
      <Navbar isLoggedIn={!!token} />
      <div className="waiver-background" style={{
        backgroundColor: '#7300de',
        backgroundImage: `url(${foggybirds})`,
        backgroundAttachment: 'fixed',
        backgroundPosition: 'center',
        backgroundRepeat: 'repeat',
        backgroundSize: 'auto',
        backgroundBlendMode: 'overlay',
        minHeight: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}>
        <div className="logo-container">
          <img src={logo} alt="Logo" className="logo" />
        </div>
        <div className="waiver-form-container">
          <h1>Submit Your Waiver</h1>
          <form onSubmit={handleSubmit}>
            <label>Emergency Contact Name</label>
            <input type="text" name="emergencyContactName" required />
            <label>Emergency Contact Phone</label>
            <input 
              type="tel" 
              name="emergencyContactPhone" 
              value={emergencyContactPhone} 
              onChange={handlePhoneChange}
              required 
            />

            <label>Unconditional Waiver and Release</label>
            <div className="waiver-text-container">
                <pre>{waiverText}</pre>
            </div>
            
            <label>Signature</label>
            <div className="signature-area">
              <SignatureCanvas
                penColor="black"
                ref={sigPad}
                canvasProps={{
                  width: 500,
                  height: 200,
                  className: "signature-canvas",
                }}
              />
              <button type="button" className="clear-button" onClick={clearSignature}>
                Clear
              </button>
            </div>

            <label>
              Please type your full name to confirm: 
              <input 
                type="text" 
                value={typedName}
                onChange={(e) => setTypedName(e.target.value)} 
                placeholder="Your Full Name" 
                required
              />
            </label>

            {error && <p style={{ color: "red" }}>{error}</p>}

            <button type="submit">Submit Waiver</button>
          </form>
        </div>
      </div>
    </>
  );
}

export default WaiverForm;