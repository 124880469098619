import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useToken from '@galvanize-inc/jwtdown-for-react';
import Navbar from './Navbar';
import './OfferingsPage.css'; 

import logo from './01logo.png'; 
import foggybirds from './foggy-birds.png';

const OfferingsPage = () => {
    const { token } = useToken();
    const [classes, setClasses] = useState([]);
    const [selectedClass, setSelectedClass] = useState(null); // For expanded class details
    const [userTokens, setUserTokens] = useState(0);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    // Fetch the user's available tokens
    useEffect(() => {
        const fetchUserTokens = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_HOST}/user/current/`, {
                    headers: { Authorization: `Bearer ${token}` },
                });
                if (response.ok) {
                    const userData = await response.json();
                    setUserTokens(userData.tokens);
                } else {
                    setError("Failed to fetch user tokens.");
                }
            } catch (error) {
                console.error("Error fetching user tokens:", error);
                setError("Error fetching user tokens.");
            }
        };
        if (token) {
            fetchUserTokens();
        }
    }, [token]);

    // Fetch all available classes
    useEffect(() => {
        const fetchClasses = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_HOST}/classes/`, {
                    headers: { Authorization: `Bearer ${token}` },
                });
                if (response.ok) {
                    const classData = await response.json();

                    // Filter classes to show only those in the future
                    const filteredClasses = classData.filter(cls => new Date(cls.class_date) >= new Date());

                    // Sort classes by date (ascending)
                    setClasses(filteredClasses.sort((a, b) => new Date(a.class_date) - new Date(b.class_date)));
                } else {
                    setError("Failed to fetch classes.");
                }
            } catch (error) {
                console.error("Error fetching classes:", error);
                setError("Error fetching classes.");
            }
        };
        if (token) {
            fetchClasses();
        }
    }, [token]);

    // Handle class selection for details (fetch instructor name)
    const handleSelectClass = async (classId) => {
        try {
            const classResponse = await fetch(`${process.env.REACT_APP_API_HOST}/classes/${classId}`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            if (classResponse.ok) {
                const classDetails = await classResponse.json();
                
                // Fetch the instructor details using the instructor_id
                const instructorResponse = await fetch(`${process.env.REACT_APP_API_HOST}/user/byid/${classDetails.instructor_id}`, {
                    headers: { Authorization: `Bearer ${token}` },
                });
                if (instructorResponse.ok) {
                    const instructorData = await instructorResponse.json();
                    classDetails.instructor_name = `${instructorData.first_name} ${instructorData.last_name}`;
                } else {
                    classDetails.instructor_name = "Instructor not found";
                }
                
                setSelectedClass(classDetails);
            } else {
                setError("Failed to fetch class details.");
            }
        } catch (error) {
            console.error("Error fetching class details:", error);
            setError("Error fetching class details.");
        }
    };

    // Handle class enrollment
    const handleEnroll = async (classId, tokensRequired) => {
        if (userTokens < tokensRequired) {
            setError("You don't have enough tokens to enroll.");
            return;
        }

        try {
            const decodedToken = JSON.parse(atob(token.split('.')[1]));
            const userId = parseInt(decodedToken?.account?.id, 10);

            if (isNaN(userId)) {
                setError("Invalid user ID.");
                return;
            }

            const response = await fetch(`${process.env.REACT_APP_API_HOST}/enrollments/enroll`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify({ class_id: classId, user_id: userId }),
            });

            if (response.ok) {
                setUserTokens((prev) => prev - tokensRequired);
                alert("Enrolled successfully!");
                navigate("/enrolled-classes");
            } else {
                setError("You are already enrolled in this class.");
            }
        } catch (error) {
            console.error("Error enrolling in class:", error);
            setError("Error enrolling in class.");
        }
    };

    // Handle going back to the class list
    const handleBack = () => {
        setSelectedClass(null);
    };

    // Logged Out View
    if (!token) {
        return (
            <div className="logged-out-container">
                <div className="logo-container">
                    <img src={logo} alt="Yoganautics Logo" className="logo" />
                </div>
                <h1>Welcome to Yoganautics</h1>
                <p>Join us today for a transformative yoga experience!</p>
                <div className="logged-out-actions">
                    <button className="btn login-btn" onClick={() => navigate('/login')}>
                        Log In
                    </button>
                    <button className="btn create-btn" onClick={() => navigate('/signup')}>
                        Create Account
                    </button>
                </div>
            </div>
        );
    }

    // Logged In View (Offerings Page)
    return (
        <>
            {/* Render Navbar */}
            <Navbar isLoggedIn={!!token} />

            <div className="offerings-background" style={{
                background: `linear-gradient(135deg, rgba(115, 0, 222, 0.8), rgba(172, 72, 232, 0.9)), url(${foggybirds})`,
                backgroundAttachment: 'fixed',
                backgroundPosition: 'center',
                backgroundRepeat: 'repeat',
                backgroundSize: 'auto',
                backgroundBlendMode: 'overlay',
                minHeight: '100vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }}>
                <div className="logo-container">
                    <img src={logo} alt="Logo" className="logo" />
                </div>

                <div className="offerings-container">
                    <p style={{ textDecoration: 'underline' }}>Discover the Benefit</p>
                    <p>Unlock a holistic yoga experience that will rejuvenate your mind and body. Each class is designed to challenge and nurture your physical and spiritual growth. Enroll today and begin your journey towards inner peace!</p>
                    {error && <p style={{ color: 'red' }}>{error}</p>}

                    <div className="token-display">
                        <p>Your Yo-Bucks: {userTokens}</p>
                        <button className="buy-tokens-btn" onClick={() => navigate('/purchase-tokens')}>
                            Purchase Yo-Bucks
                        </button>
                    </div>

                    {/* Show class list if no class is selected */}
                    {!selectedClass && (
                        <div className="class-list">
                            {classes.length > 0 ? (
                                classes.map((cls) => (
                                    <div key={cls.id} className="class-item">
                                        <h1>{cls.title}</h1>
                                        <h2>{cls.class_date}</h2>
                                        <p>{cls.location}</p>
                                        <button onClick={() => handleSelectClass(cls.id)}>View Details</button>
                                    </div>
                                ))
                            ) : (
                                <p>No classes available at the moment.</p>
                            )}
                        </div>
                    )}

                    {/* Show class details if a class is selected */}
                    {selectedClass && (
                        <div className="class-details">
                            <h2>Class Details: {selectedClass.title}</h2>
                            <p>{selectedClass.description}</p>
                            <p>Instructor: {selectedClass.instructor_name}</p>
                            <p>Yo-Bucks Required: {selectedClass.tokens_required}</p>
                            <button onClick={() => handleEnroll(selectedClass.id, selectedClass.tokens_required)}>
                                Enroll
                            </button>
                            <button className="back-button" style={{ marginLeft: '15px' }} onClick={handleBack}>Back</button>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

export default OfferingsPage;
