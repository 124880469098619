import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useToken from '@galvanize-inc/jwtdown-for-react';
import './PurchaseTokensPage.css';
import './logo.css'; // Add custom CSS for styling
import logo from './01logo.png'; // Import your logo
import Navbar from './Navbar';

const PurchaseTokensPage = () => {
    const { token } = useToken();
    const [tokenAmount, setTokenAmount] = useState(10); // Default amount of Yo-Bucks to buy
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    const YO_BUCK_COST = 1.25; // Cost per Yo-Buck

    const handleTokenSelect = (amount) => {
        setTokenAmount(amount);
    };

    const handlePurchase = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_HOST}/paypal/create-payment`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify({ token_amount: tokenAmount }), // Use selected token amount
            });

            const data = await response.json();

            if (response.ok && data.approval_url) {
                window.location.href = data.approval_url; // Redirect to PayPal for payment
            } else {
                setError('Failed to initiate PayPal payment.');
            }
        } catch (error) {
            console.error('Error initiating payment:', error);
            setError('Error initiating payment.');
        }
    };

    // Calculate total cost based on selected amount of Yo-Bucks
    const totalCost = (tokenAmount * YO_BUCK_COST).toFixed(2); // Ensuring two decimal places

    // Logged Out View
    if (!token) {
        return (
            <div className="logged-out-container">
                <h1>You’re not logged in</h1>
                <p>Please log in or create an account to purchase Yo-Bucks.</p>
                <div className="logged-out-actions">
                    <button className="btn login-btn" onClick={() => navigate('/login')}>
                        Log In
                    </button>
                    <button className="btn create-btn" onClick={() => navigate('/signup')}>
                        Create Account
                    </button>
                </div>
            </div>
        );
    }

    // Logged In View (Purchase Tokens)
    return (
        <>
            <Navbar isLoggedIn={!!token} />
            <div className="purchase-tokens-container">
                {/* Logo */}
                <div className="logo-container">
                    <img src={logo} alt="Yoganautics Logo" className="logo" />
                </div>

                {/* Purchase Section */}
                <h2>Purchase Yo-Bucks</h2>
                <p>Get your Yo-Bucks and enjoy unlimited access to our premium yoga classes.</p>

                {error && <p style={{ color: 'red' }}>{error}</p>}

                <div className="purchase-section">
                    <h2>Select the amount of Yo-Bucks</h2>
                    <p>Choose from the following options:</p>

                    {/* Button choices for token amounts */}
                    <div className="token-options">
                        <button
                            className={`token-option-btn ${tokenAmount === 10 ? 'active' : ''}`}
                            onClick={() => handleTokenSelect(10)}
                        >
                            10 Yo-Bucks
                        </button>
                        <button
                            className={`token-option-btn ${tokenAmount === 25 ? 'active' : ''}`}
                            onClick={() => handleTokenSelect(25)}
                        >
                            25 Yo-Bucks
                        </button>
                        <button
                            className={`token-option-btn ${tokenAmount === 50 ? 'active' : ''}`}
                            onClick={() => handleTokenSelect(50)}
                        >
                            50 Yo-Bucks
                        </button>
                    </div>

                    {/* Display the total cost dynamically */}
                    <p><strong>Total Cost: ${totalCost}</strong></p>

                    <button className="purchase-btn" onClick={handlePurchase}>
                        Purchase Yo-Bucks
                    </button>

                    <button className="back-btn" onClick={() => navigate('/offerings')}>
                        Back to Offerings
                    </button>

                    {/* Disclaimer */}
                    <p className="disclaimer">
                        <strong>Note:</strong> Yo-Bucks are non-refundable. However, if you can't make it to a class, you can disenroll to get your Yo-Bucks back.
                    </p>
                </div>
            </div>
        </>
    );
};

export default PurchaseTokensPage;
