import React, { useState, useEffect } from 'react';
import useToken from '@galvanize-inc/jwtdown-for-react';
import { useNavigate } from 'react-router-dom';
import './ClassList.css'; // Assuming you want to use a similar style
import Navbar from './Navbar';

const UserList = () => {
  const { token, isLoading } = useToken();
  const navigate = useNavigate();
  const [users, setUsers] = useState([]);
  const [userRole, setUserRole] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);
  const [editingUserId, setEditingUserId] = useState(null);
  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    username: '',
    email: '',
    phone_number: '',
    role: 3,
    tokens: 0,
    bio: '',
    is_waiver_submitted: false,
    is_contract_submitted: false,
  });
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });

  useEffect(() => {
    const fetchUserAndUsers = async () => {
      if (token) {
        try {
          // Fetch current user details
          const userResponse = await fetch(`${process.env.REACT_APP_API_HOST}/user/current/`, {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${token}`,
            },
          });
          const userData = await userResponse.json();
          if (userResponse.ok) {
            setUserRole(userData.role);
            setCurrentUser(userData);

            if (userData.role === 1) {
              // Fetch all users if current user is admin
              const usersResponse = await fetch(`${process.env.REACT_APP_API_HOST}/user/`, {
                method: 'GET',
                headers: {
                  'Authorization': `Bearer ${token}`,
                },
              });
              const usersData = await usersResponse.json();
              if (usersResponse.ok) {
                setUsers(usersData);
              } else {
                alert('Failed to load users.');
              }
            } else {
              navigate('/'); // Redirect if not admin
            }
          }
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      }
    };
    fetchUserAndUsers();
  }, [token, navigate]);

  const handleSort = (key) => {
    setSortConfig((prevSortConfig) => ({
      key,
      direction: prevSortConfig.key === key && prevSortConfig.direction === 'asc' ? 'desc' : 'asc',
    }));
  };

  const sortedUsers = React.useMemo(() => {
    if (!sortConfig.key) return users;
    return [...users].sort((a, b) => {
      const aValue = a[sortConfig.key];
      const bValue = b[sortConfig.key];
      
      // For string fields, convert to lowercase for sorting but leave display unaffected
      if (typeof aValue === 'string' && typeof bValue === 'string') {
        return sortConfig.direction === 'asc'
          ? aValue.toLowerCase().localeCompare(bValue.toLowerCase())
          : bValue.toLowerCase().localeCompare(aValue.toLowerCase());
      }
      
      // For non-string fields, continue with standard comparison
      if (aValue < bValue) {
        return sortConfig.direction === 'asc' ? -1 : 1;
      }
      if (aValue > bValue) {
        return sortConfig.direction === 'asc' ? 1 : -1;
      }
      return 0;
    });
  }, [users, sortConfig]);
  
  const handleEditClick = (user) => {
    setEditingUserId(user.id);
    setFormData({
      first_name: user.first_name,
      last_name: user.last_name,
      username: user.username,
      email: user.email,
      phone_number: user.phone_number,
      role: user.role,
      tokens: user.tokens,
      bio: user.bio,
      is_waiver_submitted: user.is_waiver_submitted,
      is_contract_submitted: user.is_contract_submitted,
    });
  };

  const handleCancelEdit = () => setEditingUserId(null);

  const handleChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });

  const handleSave = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_HOST}/user/${editingUserId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify(formData),
      });

      const result = await response.json();
      if (response.ok) {
        setUsers((prevUsers) =>
          prevUsers.map((user) => (user.id === editingUserId ? result : user))
        );
        setEditingUserId(null);
      } else {
        alert(`Failed to update user: ${result.message}`);
      }
    } catch (error) {
      console.error('Error updating user:', error);
    }
  };

  const handleRevoke = async (userId) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_HOST}/user/${userId}/revoke`, {
        method: 'PUT',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
      if (response.ok) {
        setUsers((prevUsers) =>
          prevUsers.map((user) =>
            user.id === userId ? { ...user, role: 4 } : user
          )
        );
      } else {
        alert('Failed to revoke user.');
      }
    } catch (error) {
      console.error('Error revoking user:', error);
    }
  };

  if (isLoading || userRole === null) {
    return <div>Loading...</div>;
  }

  return (
    <div className="class-list-page">
      <Navbar isLoggedIn={!!token} />
      <h1>User List</h1>
      <table className="class-list-table">
        <thead>
          <tr>
            <th>
              Role
              <button onClick={() => handleSort('role')}>Sort</button>
            </th>
            <th>
              Last Name
              <button onClick={() => handleSort('last_name')}>Sort</button>
            </th>
            <th>
              First Name
              <button onClick={() => handleSort('first_name')}>Sort</button>
            </th>
            <th>
              Username
              <button onClick={() => handleSort('username')}>Sort</button>
            </th>
            <th>
              Email Address
              <button onClick={() => handleSort('email')}>Sort</button>
            </th>
            <th>
              Phone Number
              <button onClick={() => handleSort('phone_number')}>Sort</button>
            </th>
            <th>
              Tokens
              <button onClick={() => handleSort('tokens')}>Sort</button>
            </th>
            <th>
              Yogography
              <button onClick={() => handleSort('bio')}>Sort</button>
            </th>
            <th>
              Waiver Submitted
              <button onClick={() => handleSort('is_waiver_submitted')}>Sort</button>
            </th>
            <th>
              Contract Submitted
              <button onClick={() => handleSort('is_contract_submitted')}>Sort</button>
            </th>
            <th>
              Revoked At
              <button onClick={() => handleSort('revoked_at')}>Sort</button>
            </th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {sortedUsers.map((user) => (
            <tr key={user.id}>
              {editingUserId === user.id ? (
                <>
                  <td>
                    <select
                      name="role"
                      value={formData.role}
                      onChange={handleChange}
                    >
                      <option value={1}>Admin</option>
                      <option value={2}>Teacher</option>
                      <option value={3}>Student</option>
                      <option value={4}>Revoked</option>
                    </select>
                  </td>
                  <td>
                    <input
                      type="text"
                      name="last_name"
                      value={formData.last_name}
                      onChange={handleChange}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="first_name"
                      value={formData.first_name}
                      onChange={handleChange}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="username"
                      value={formData.username}
                      onChange={handleChange}
                    />
                  </td>
                  <td>
                    <input
                      type="email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="phone_number"
                      value={formData.phone_number}
                      onChange={handleChange}
                    />
                  </td>
                  <td>
                    <input
                      type="number"
                      name="tokens"
                      value={formData.tokens}
                      onChange={handleChange}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="bio"
                      value={formData.bio}
                      onChange={handleChange}
                    />
                  </td>
                  <td>
                    <select
                      name="is_waiver_submitted"
                      value={formData.is_waiver_submitted}
                      onChange={handleChange}
                    >
                      <option value={true}>Yes</option>
                      <option value={false}>No</option>
                    </select>
                  </td>
                  <td>
                    <select
                      name="is_contract_submitted"
                      value={formData.is_contract_submitted}
                      onChange={handleChange}
                    >
                      <option value={true}>Yes</option>
                      <option value={false}>No</option>
                    </select>
                  </td>
                  <td>{user.revoked_at ? new Date(user.revoked_at).toLocaleString() : 'N/A'}</td>
                  <td>
                    <button onClick={handleSave} className="save-button">Save</button>
                    <button onClick={handleCancelEdit} className="cancel-button">Cancel</button>
                  </td>
                </>
              ) : (
                <>
                  <td>{user.role === 1 ? 'Admin' : user.role === 2 ? 'Teacher' : user.role === 3 ? 'Student' : 'Revoked'}</td>
                  <td>{user.last_name}</td>
                  <td>{user.first_name}</td>
                  <td>{user.username}</td>
                  <td>{user.email}</td>
                  <td>{user.phone_number}</td>
                  <td>{user.tokens}</td>
                  <td>{user.bio}</td>
                  <td>{user.is_waiver_submitted ? 'Yes' : 'No'}</td>
                  <td>{user.is_contract_submitted ? 'Yes' : 'No'}</td>
                  <td>{user.revoked_at ? new Date(user.revoked_at).toLocaleString() : 'N/A'}</td>
                  <td>
                    <button onClick={() => handleEditClick(user)} className="edit-button">
                      Edit
                    </button>
                    {user.role !== 4 && (
                      <button onClick={() => handleRevoke(user.id)} className="revoke-button">
                        Revoke
                      </button>
                    )}
                  </td>
                </>
              )}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default UserList;
