import React, { useEffect, useState } from 'react';
import styles from './YogaSimulation.module.css'; 
import { Howl } from 'howler';  // Sound library

const getRandomColor = () => {
  const letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

const YogaSimulation = () => {
  const [color, setColor] = useState('#000000');  // Initial color is black
  const [sound, setSound] = useState(null);  // Store Howl instance
  const [isPlaying, setIsPlaying] = useState(false);  // Track if animation and sound are playing
  const [shapeColors, setShapeColors] = useState(Array.from({ length: 10 }, () => getRandomColor()));  // Store random colors for each shape
  const [message, setMessage] = useState('');  // State for the directions
  const [showMessage, setShowMessage] = useState(false);  // Control showing message

  const shapesArray = Array.from({ length: 10 }, (_, i) => i);  // Create an array of 10 shapes

  useEffect(() => {
    let colorInterval = null;
    let colorUpdateInterval = null;
    let messageTimeouts = [];

    if (isPlaying) {
      // Background color transitions
      const colorIntervals = ['#000033', '#330066', '#003366', '#330033'];  // Dark spacey colors
      let currentIndex = 0;
      colorInterval = setInterval(() => {
        setColor(colorIntervals[currentIndex]);
        currentIndex = (currentIndex + 1) % colorIntervals.length;
      }, 5000);  // Change every 5 seconds

      // Update shape colors periodically to randomize
      colorUpdateInterval = setInterval(() => {
        setShapeColors(Array.from({ length: 10 }, () => getRandomColor()));
      }, 10000);  // Change colors every 10 seconds

      // Set up timed messages with fade out and fade in
      const messageSteps = [
        { delay: 1000, text: 'Welcome, relax and enjoy...' },
        { delay: 15000, text: 'Take a deep breath in and slowly release...' },
        { delay: 30000, text: 'Let go of all worries, be in this moment.' },
        { delay: 45000, text: 'Feel the calmness in your body, mind, and soul.' },
        { delay: 60000, text: 'Embrace the tranquility, and let your thoughts drift.' },
        { delay: 75000, text: 'Continue your journey with stillness and peace.' },
        { delay: 90000, text: 'You are present. You are at peace.' },
      ];

      messageSteps.forEach(({ delay, text }) => {
        const timeoutId = setTimeout(() => {
          setShowMessage(false);
          setTimeout(() => {
            setMessage(text);
            setShowMessage(true);
          }, 2000);  // Smooth transition between messages
        }, delay);
        messageTimeouts.push(timeoutId);
      });

      return () => {
        clearInterval(colorUpdateInterval);
        clearInterval(colorInterval);
        messageTimeouts.forEach(clearTimeout);
      };
    }
  }, [isPlaying]);

  const handleStart = () => {
    setIsPlaying(true);
    setMessage('');  // Reset message when starting
    setShowMessage(false);

    // Initialize sound
    const soundInstance = new Howl({
      src: ['/relaxing.mp3'],
      loop: true,
      volume: 0,
      onload: () => {
        soundInstance.fade(0, 0.3, 2000);  // Fade in to volume 0.5 over 2 seconds
        soundInstance.play();
      },
    });
    setSound(soundInstance);
  };

  const handleStop = () => {
    setIsPlaying(false);
    setColor('#000000');  // Reset to black screen

    // Stop the sound and unload the Howl instance
    if (sound) {
      sound.fade(0.3, 0, 2000);  // Fade out sound over 2 seconds
      sound.once('fade', () => {
        sound.stop();  // Stop sound after fading out
        sound.unload();  // Unload the sound instance
        setSound(null);  // Reset the sound state
      });
    }

    // Stop the messages
    setMessage('');
    setShowMessage(false);
  };

  return (
    <div className={styles.yogaSimulation} style={{ backgroundColor: color }}>
      <div className={styles.starsBackground}></div> {/* Add the stars background */}

      {/* Display the message with fade in/out effect */}
      <div className={`${styles.message} ${showMessage ? styles.show : styles.hide}`}>
        {message}
      </div>

      {!isPlaying && (
        <button onClick={handleStart} className={styles.startButton}>
          Start Yoga Session
        </button>
      )}

      {isPlaying && (
        <>
          {/* Render multiple shapes with dynamic colors */}
          {shapesArray.map((_, index) => (
            <div
              key={index}
              className={`${styles.animatedShape} ${styles[`shape${index % 10}`]} ${styles[`path${index % 10}`]}`}
              style={{ backgroundColor: shapeColors[index] }}  // Apply random color
            ></div>
          ))}

          <button onClick={handleStop} className={styles.stopButton}>
            Stop Yoga Session
          </button>
        </>
      )}

      {/* Home button */}
      <button onClick={() => window.location.href = '/'} className={styles.homeButton}>
        Go Home
      </button>
    </div>
  );
};

export default YogaSimulation;
