import React, { useState, useEffect } from 'react';
import useToken from '@galvanize-inc/jwtdown-for-react';
import { useNavigate } from 'react-router-dom';
import './ClassList.css'; // Assuming the CSS is here
import Navbar from './Navbar';

const ClassList = () => {
  const { token, isLoading } = useToken();
  const navigate = useNavigate();
  const [classes, setClasses] = useState([]);
  const [userRole, setUserRole] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);
  const [editingClassId, setEditingClassId] = useState(null);
  const [creatingNewClass, setCreatingNewClass] = useState(false); // For new class creation
  const [formData, setFormData] = useState({
    title: '',
    description: '',
    class_date: '',
    class_time: '',
    duration: 0,
    level: '',
    capacity: 0,
    instructor_pay: 25.00, // Default instructor pay
    tokens_required: 1, // Default tokens required
    instructor_id: null, // Track the instructor ID
    class_location: '', // Added class_location field
  });
  const [instructors, setInstructors] = useState([]); // Store instructor data

  useEffect(() => {
    const fetchUserAndClasses = async () => {
      if (token) {
        try {
          // Fetch current user details
          const userResponse = await fetch(`${process.env.REACT_APP_API_HOST}/user/current/`, {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${token}`,
            },
          });
          const userData = await userResponse.json();
          if (userResponse.ok) {
            setUserRole(userData.role);
            setCurrentUser(userData);

            // Fetch all classes
            const classResponse = await fetch(`${process.env.REACT_APP_API_HOST}/classes/`, {
              method: 'GET',
              headers: {
                'Authorization': `Bearer ${token}`,
              },
            });
            const classData = await classResponse.json();
            if (classResponse.ok) {
              setClasses(classData.sort((a, b) => new Date(b.class_date) - new Date(a.class_date)));
            }

            // Fetch instructors (admins and teachers)
            if (userData.role === 1) {
              const usersResponse = await fetch(`${process.env.REACT_APP_API_HOST}/user/?role=1,2`, {
                method: 'GET',
                headers: {
                  'Authorization': `Bearer ${token}`,
                },
              });
              const usersData = await usersResponse.json();
              if (usersResponse.ok) {
                // Manually filter users to include only Admins (role=1) and Teachers (role=2)
                const filteredInstructors = usersData.filter(
                  (instructor) => instructor.role === 1 || instructor.role === 2
                );
                setInstructors(filteredInstructors);
              }
            }
          } else {
            navigate('/enrolled-classes'); // Redirect if not admin or teacher
          }
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      }
    };
    fetchUserAndClasses();
  }, [token, navigate]);

  const handleEditClick = (classItem) => {
    setEditingClassId(classItem.id);
    setFormData({
      title: classItem.title,
      description: classItem.description,
      class_date: classItem.class_date,
      class_time: classItem.class_time,
      duration: classItem.duration,
      level: classItem.level,
      capacity: classItem.capacity,
      instructor_pay: classItem.instructor_pay || 25.00,
      tokens_required: classItem.tokens_required || 1,
      instructor_id: classItem.instructor_id,
      class_location: classItem.class_location || '',
    });
  };

  const handleCancelEdit = () => {
    setEditingClassId(null);
    setCreatingNewClass(false); // Reset create mode
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSave = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_HOST}/classes/${editingClassId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify(formData),
      });

      const result = await response.json();
      if (response.ok) {
        setClasses((prevClasses) =>
          prevClasses.map((classItem) => (classItem.id === editingClassId ? result : classItem))
        );
        setEditingClassId(null);
      } else {
        alert(`Failed to update class: ${result.message}`);
      }
    } catch (error) {
      console.error('Error updating class:', error);
    }
  };

  const handleDelete = async (classId) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_HOST}/classes/${classId}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
      if (response.ok) {
        setClasses((prevClasses) => prevClasses.filter((classItem) => classItem.id !== classId));
      } else {
        alert('Failed to delete class');
      }
    } catch (error) {
      console.error('Error deleting class:', error);
    }
  };

  const handleCreateNewClass = () => {
    setCreatingNewClass(true);
    setFormData({
      title: '',
      description: '',
      class_date: '',
      class_time: '',
      duration: 0,
      level: '',
      capacity: 0,
      instructor_pay: 25.00,
      tokens_required: 1,
      instructor_id: currentUser?.id || null,
      class_location: '', // Reset class_location field
    });
  };

  const handleCreateClassSave = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_HOST}/classes`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify(formData),
      });

      const result = await response.json();
      if (response.ok) {
        setClasses([result, ...classes]); // Add the new class to the top of the list
        setCreatingNewClass(false);
      } else {
        alert(`Failed to create class: ${result.message}`);
      }
    } catch (error) {
      console.error('Error creating class:', error);
    }
  };

  if (isLoading || userRole === null) {
    return <div>Loading...</div>;
  }

  return (
    <div className="class-list-page">
      <Navbar isLoggedIn={!!token} />
      <h1>Class List</h1>
      <table className="class-list-table">
        <thead>
          <tr>
            <th>Title</th>
            <th>Description</th>
            <th>Date</th>
            <th>Time</th>
            <th>Duration</th>
            <th>Level</th>
            <th>Capacity</th>
            <th>Tokens Required</th>
            <th>Location</th>
            <th>Instructor</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {/* Empty row for creating a new class */}
          {creatingNewClass ? (
            <tr>
              <td>
                <input
                  type="text"
                  name="title"
                  value={formData.title}
                  onChange={handleChange}
                />
              </td>
              <td>
                <textarea
                  name="description"
                  value={formData.description}
                  onChange={handleChange}
                  rows="4"
                  cols="30"
                />
              </td>
              <td>
                <input
                  type="date"
                  name="class_date"
                  value={formData.class_date}
                  onChange={handleChange}
                />
              </td>
              <td>
                <input
                  type="time"
                  name="class_time"
                  value={formData.class_time}
                  onChange={handleChange}
                />
              </td>
              <td>
                <input
                  type="number"
                  name="duration"
                  value={formData.duration}
                  onChange={handleChange}
                />
              </td>
              <td>
                <input
                  type="text"
                  name="level"
                  value={formData.level}
                  onChange={handleChange}
                />
              </td>
              <td>
                <input
                  type="number"
                  name="capacity"
                  value={formData.capacity}
                  onChange={handleChange}
                />
              </td>
              <td>
                <input
                  type="number"
                  name="tokens_required"
                  value={formData.tokens_required}
                  onChange={handleChange}
                />
              </td>
              <td>
                <input
                  type="text"
                  name="class_location"
                  value={formData.class_location}
                  onChange={handleChange}
                />
              </td>
              <td>
                {userRole === 1 ? (
                  <select
                    name="instructor_id"
                    value={formData.instructor_id}
                    onChange={handleChange}
                  >
                    <option value="">Select Instructor</option>
                    {instructors.map((instructor) => (
                      <option key={instructor.id} value={instructor.id}>
                        {`${instructor.first_name} ${instructor.last_name} (${instructor.role === 1 ? 'Admin' : 'Teacher'})`}
                      </option>
                    ))}
                  </select>
                ) : (
                  <input
                    type="text"
                    value={`${currentUser?.first_name} ${currentUser?.last_name}`}
                    disabled
                  />
                )}
              </td>
              <td>
                <button onClick={handleCreateClassSave} className="save-button">Create</button>
                <button onClick={handleCancelEdit} className="cancel-button">Cancel</button>
              </td>
            </tr>
          ) : (
            <tr>
              <td colSpan="10"></td>
              <td>
                <button onClick={handleCreateNewClass} className="create-button">Create Class</button>
              </td>
            </tr>
          )}
          {classes.map((classItem) => (
            <tr key={classItem.id}>
              {editingClassId === classItem.id ? (
                <>
                  <td>
                    <input
                      type="text"
                      name="title"
                      value={formData.title}
                      onChange={handleChange}
                    />
                  </td>
                  <td>
                    <textarea
                      name="description"
                      value={formData.description}
                      onChange={handleChange}
                      rows="4"
                      cols="30"
                    />
                  </td>
                  <td>
                    <input
                      type="date"
                      name="class_date"
                      value={formData.class_date}
                      onChange={handleChange}
                    />
                  </td>
                  <td>
                    <input
                      type="time"
                      name="class_time"
                      value={formData.class_time}
                      onChange={handleChange}
                    />
                  </td>
                  <td>
                    <input
                      type="number"
                      name="duration"
                      value={formData.duration}
                      onChange={handleChange}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="level"
                      value={formData.level}
                      onChange={handleChange}
                    />
                  </td>
                  <td>
                    <input
                      type="number"
                      name="capacity"
                      value={formData.capacity}
                      onChange={handleChange}
                    />
                  </td>
                  <td>{formData.tokens_required}</td>
                  <td>
                    <input
                      type="text"
                      name="class_location"
                      value={formData.class_location}
                      onChange={handleChange}
                    />
                  </td>
                  <td>
                    {userRole === 1 ? (
                      <select
                        name="instructor_id"
                        value={formData.instructor_id}
                        onChange={handleChange}
                      >
                        <option value="">Select Instructor</option>
                        {instructors.map((instructor) => (
                          <option key={instructor.id} value={instructor.id}>
                            {`${instructor.first_name} ${instructor.last_name} (${instructor.role === 1 ? 'Admin' : 'Teacher'})`}
                          </option>
                        ))}
                      </select>
                    ) : (
                      <input
                        type="text"
                        value={instructors[classItem.instructor_id] || 'Loading...'}
                        disabled
                      />
                    )}
                  </td>
                  <td>
                    <button onClick={handleSave} className="save-button">Save</button>
                    <button onClick={handleCancelEdit} className="cancel-button">Cancel</button>
                  </td>
                </>
              ) : (
                <>
                  <td>{classItem.title}</td>
                  <td>{classItem.description}</td>
                  <td>{classItem.class_date}</td>
                  <td>{classItem.class_time}</td>
                  <td>{classItem.duration} mins</td>
                  <td>{classItem.level}</td>
                  <td>{classItem.capacity}</td>
                  <td>{classItem.tokens_required}</td>
                  <td>{classItem.class_location}</td>
                  <td>{instructors.find(i => i.id === classItem.instructor_id)?.first_name} {instructors.find(i => i.id === classItem.instructor_id)?.last_name}</td>
                  <td>
                    {(userRole === 1 || (userRole === 2 && currentUser.id === classItem.instructor_id)) && (
                      <>
                        <button onClick={() => handleEditClick(classItem)} className="edit-button">
                          Edit
                        </button>
                        <button onClick={() => handleDelete(classItem.id)} className="delete-button">
                          Delete
                        </button>
                      </>
                    )}
                  </td>
                </>
              )}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ClassList;
